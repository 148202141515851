import { parseBrandSafetyFlags, utilsConsole } from '../utils/playerUtils';

/**
 *
 * @author: Alena Marečková
 *
 * @create 2020-07-22
 *
 * @version 1.0
 * @description funkce pro zasílání událostí vp playeru s odpovídajícím daty do dataLayeru Apps + Web (GTM 2.0)
 *
 */

export function AppWebService(config) {
    utilsConsole('AppWebService config', config);
    const settings = {
        contentId: config.video.id || config.appWeb.videoId || '-1',
        contentSafety: parseBrandSafetyFlags(config.video.flags),
        contentPublished: config.video.dateStart || '',
        contentTags: config.video.contentTags || [],
        contentTitle: config.video.title || config.video.description || 'title not setting',
        playerVariant: config.video.playerVariant || config.appWeb.playerVariant,
        contentType: config.video.contentType || config.appWeb.contentType || 'standard',
        contentAuthor: config.video.source,
        contentPremium: config.premium || config.appWeb.contentPremium,
        timeLength: parseInt(config.video.durationSeconds, 10) || 0,
        playerElementId: config.video.elementId,
        playerApp: 'vpPlayer-v2.0',
        playerType: config.video.playerType || config.appWeb.playerType,
        settingsAutoplay: config.video.autoPlay,
        settingsMute: config.video.mute,
        settingsFullscreen: false,
        show: 'show' in config.video ? config.video.show : config.appWeb.show,
    };
    const self = this;

    utilsConsole('AppWebService settings', settings);

    if (config.video.category && config.video.category.name) {
        settings.contentCategory = config.video.category.name;
    } else if (config.appWeb.categoryName) {
        settings.contentCategory = config.video.categoryName || config.appWeb.categoryName;
    } else {
        settings.contentCategory = 'category not avalaible';
    }

    if (config.video.magazineName) {
        settings.contentEntity = config.video.magazineName;
    } else {
        settings.contentEntity = '';
    }

    if (settings.show) {
        settings.contentShow = config.appWeb.show;
        settings.contentEpisode = settings.contentTitle;
    }

    settings.noDidomi = false;
    if ('appWeb' in config && 'noDidomi' in config.appWeb) {
        settings.noDidomi = config.appWeb.noDidomi || false;
    }

    /**
     *  funkce pro vytváření objektů, které se budou odesílat do dataLayer
     */

    /**
     * @description pomocná funkce pro vytvoření time objektu do dat
     * @param {number} timePosition - pozice videa (tj. na kolikáté sekundě uživatel momentálně je)
     *
     * @return {object} timeObj - objekt s časovými údaji pro odeslání do dataLayeru
     */
    self.getTimeObj = (timePosition) => {
        const timeObj = {
            length: settings.timeLength,
        };

        // eslint-disable-next-line no-restricted-globals
        if (isFinite(timePosition)) {
            timeObj.position = Math.round(timePosition);
            // eslint-disable-next-line no-restricted-globals
        } else if (isFinite(window.vpPlayer(settings.playerElementId).getPosition())) {
            timeObj.position = Math.round(window.vpPlayer(settings.playerElementId).getPosition());
        } else {
            timeObj.position = null;
        }

        if (timePosition >= 0) {
            timeObj.position = Math.round(timePosition);
        }

        return timeObj;
    };

    /**
     * @description - pomocná funkce pro vytvoření content objektu pro odeslání do dataLayeru
     * bez paramerů, přebírají se hodnoty ze settings, mají tuto podobu:
     * {string} contentId - identifikátor videa
     * {string} contentType - typ videa (standard, live)
     * {string} contentTitle - název videa
     * {string} contentCategory - kategorie videa
     * {string} contentAuthor - author videa, pokud je
     * {string} contentShow - název pořadu, pokud je
     * {string} contentEntity - název magazinu
     * {string} contentEpisode - označení epizody, pokud je
     * {string} contentGenre - žánr, pokud je
     * {string} contentPublished - datum publikace (YYYY-MM-DD HH:MM:SS0
     * {string} contentPremium - přístupnost videa (locked / free / premium)
     * {array of string} contentSafety - typ obsahu videa (nezávadné, sex, drogy...)
     *
     * @return contentObj - objekt týkající se contentu, pro odeslání do dataLayeru
     */
    self.getContentObject = () => {
        const contentObj = {
            id: settings.contentId,
            type: settings.contentType,
            title: settings.contentTitle,
            category: settings.contentCategory,
            published: settings.contentPublished,
            entity: settings.contentEntity,
        };

        if (settings.contentSafety.length > 1) {
            contentObj.safety = self.parseSafety(settings.contentSafety);
        }

        if (settings.contentPremium === 'true') {
            contentObj.premium = 'premium';
        } else if (settings.contentPremium === 'false') {
            contentObj.premium = 'locked';
        } else {
            contentObj.premium = 'free';
        }

        if (settings.contentAuthor) {
            contentObj.author = settings.contentAuthor;
        }

        if (settings.contentShow) {
            contentObj.show = settings.contentShow;
        }

        if (settings.contentEpisode) {
            contentObj.episode = settings.contentEpisode;
        }

        if (settings.contentGenre) {
            contentObj.genre = settings.contentGenre;
        }

        if (settings.contentTags) {
            contentObj.tags = settings.contentTags;
        }

        return contentObj;
    };

    /**
     * @description - pomocná funkce pro získání objektu aktuálního nastavení playeru pro data do dataLayeru
     * bez parametrů, hodnoty bere ze settings AppWebService, které se nastavují při vytváření její instance
     * (to se týká hodnot mute, autoplay a fullscreen)
     * hodnotu resolutions získává pomocí funkce z playerUtils
     *
     * @return {object} settingsObj - objekt s nastavením pro odeslání do dataLayeru
     */
    self.getSettingsObj = () => {
        const settingsObj = {
            mute: settings.settingsMute,
            autoplay: settings.settingsAutoplay,
            fullscreen: settings.settingsFullscreen,
        };

        return settingsObj;
    };

    /**
     * @description pomocná funkce pro získání základních hodnot objektu playeru - tj. type, app a variant
     * přebírají se ze settings
     *
     * @return {object} playerObj - objekt playeru s properties type, app a variant
     */
    self.getPlayerObj = () => {
        const playerObj = {
            type: settings.playerType,
            app: settings.playerApp,
            variant: settings.playerVariant,
        };

        return playerObj;
    };

    /**
     *  funkce pro vytvoření objektů, které se budou odesílat do dataLayer - konec
     */

    /**
     * @description funkce pro aktualizaci hodnot pro video při změně videa
     *   *
     * @param {object} itemContent - objekt s údaji pro video
     * @param {string} itemContent.id - id videa
     * @param {string} itemContent.title - titulek videa
     * @param {object} itemContent.flags - objekt s flagy videa
     * @param {string} itemContent.dateStart - datum publikování videa
     * @param {string} itemContent.source - author videa
     * @param {string} itemContent.premium - přístupnost videa (locked / free / premium)
     * @param {string} itemContent.category - kategorie videa (název)
     *
     * @param {number} itemTime - délka videa
     */
    self.updateSettings = (itemContent, itemTime) => {
        utilsConsole('AppWebService.updateSettings', itemContent, itemTime);
        settings.contentId = itemContent.id || '-1';
        settings.contentTitle = itemContent.title || 'title not setting';
        settings.contentSafety = parseBrandSafetyFlags(itemContent.flags);
        settings.contentPublished = itemContent.dateStart;
        settings.contentAuthor = itemContent.source;
        settings.contentPremium = itemContent.premium;
        // settings.timeLength = itemTime || 0;
        settings.contentCategory = itemContent.category;
        settings.playerType = itemContent.playerType;
        if (itemContent.show) {
            settings.contentShow = itemContent.show;
            settings.contentEpisode = itemContent.title;
        }
        if (itemContent.magazineName) {
            settings.contentEntity = itemContent.magazineName;
        } else {
            settings.contentEntity = '';
        }
    };

    /**
     * @description funkce pro nastavení nové hodnoty jedné vlastnosti objektu settings
     * @param {object} eventData
     * @param {string} eventData.propertyName
     * @param {*} eventData.propertyVal
     */
    self.setPlayerProperty = (eventData) => {
        const { propertyName, propertyVal } = eventData;
        try {
            settings[propertyName] = propertyVal;
        } catch (e) {
            // chybu zde neresime
            /* eslint-disable no-console */
            console.error(e);
            /* eslint-enable no-console */
        }
    };

    /**
     * @description funkce pro získání hodnoty konkrétní vlastnosti objektu settings
     * @param {string} propertyName - jméno vlastnosti, které hodnotu chceme získat
     * @return {null|*} - vrací buĎ odnotu vlastnosti nebo null v případě chyby
     */
    self.getPlayerProperty = (propertyName) => {
        try {
            return settings[propertyName];
        } catch (e) {
            /* eslint-disable no-console */
            console.error(e);
            /* eslint-enable no-console */
            return null;
        }
    };

    /** funkce pro odeslání objektu do dataLayeru
     * @param {object} obj
     */
    self.sentObject2dataLayer = (obj) => {
        if (typeof window.dataLayer === 'object') {
            if (!settings.noDidomi) {
                window.didomiOnReady = window.didomiOnReady || [];
                window.didomiOnReady.push(() => {
                    window.dataLayer.push(obj);
                });
            } else {
                window.dataLayer.push(obj);
            }
        }
    };

    /**
     * @description funkce pro odesání kompletního objektu playeru do dataLayer
     * @param {object} eventData
     * @param eventData.eventType
     * @param eventData.time
     * @param eventData.clear
     */
    self.sentPlayerData = (eventData) => {
        const { clear, eventType, time } = eventData;
        const timeObj = self.getTimeObj(time);
        const settingsObj = self.getSettingsObj();
        const contentObj = self.getContentObject();
        const playerObj = self.getPlayerObj();

        playerObj.content = contentObj;
        playerObj.settings = settingsObj;
        playerObj.time = timeObj;

        const dataLayerObj = {};

        if (eventType) {
            dataLayerObj.event = eventType;
        }

        dataLayerObj.player = playerObj;

        if (clear) {
            dataLayerObj._clear = true;
        }

        self.sentObject2dataLayer(dataLayerObj);

        return dataLayerObj;
    };

    /**
     * @description funkce pro poslani seznamu doporucenych videi
     * @param {object} listData - seznam doporucenych videi s daty o nich
     * @param {string} reason - indikace druhu seznamu (otevreny behem prehravany nebo na konci videa, bere se z info.reason)
     */

    self.sentRecommendedList = (listData, reason) => {
        let listId;
        let elementId;
        let itemObj;
        let dataLayerObj;

        Object.keys(listData).forEach((item) => {
            listId = reason === 'complete' ? 'recommendedVideoEnd' : 'recommendedVideo';
            elementId = `${listId}-${item.video.id}`;
            itemObj = self.getItemObj({
                elementId,
                type: 'video',
                list: listId,
                position: item.index + 1,
                systemId: item.site,
                id: item.video.id,
                versionId: new Date(item.video.dateStart).getTime(),
                title: item.video.title,
                category: item.video.category,
                author: item.video.source,
                url: item.video.url,
            });

            dataLayerObj = {
                event: 'list_itemView',
                item: itemObj,
                _clear: true,
            };

            self.sentObject2dataLayer(dataLayerObj);
        });
    };
    /**
     * @description - funkce pro odeslani clicku na polozku do dataLayeru
     * @param {object} itemDatain - data o polozce
     * @param {string | number} - pozice polozky v seznamu
     * @param {string} reason - duvod otevreni  seznamu peo urceni id listu
     * @param {string} site - systemId polozky ve web + app
     */
    self.sentItemClick = (itemDataIn, position, reason, site) => {
        const listId = reason === 'complete' ? 'recommendedVideoEnd' : 'recommendedVideo';
        const elementId = `${listId}-${itemDataIn.id}`;
        const itemObj = self.getItemObj({
            elementId,
            type: 'video',
            list: listId,
            position,
            systemId: site,
            id: itemDataIn.id,
            versionId: new Date(itemDataIn.dateStart).getTime(),
            title: itemDataIn.title,
            category: itemDataIn.category,
            author: itemDataIn.source,
            url: itemDataIn.url,
        });

        const dataLayerObj = {
            event: 'list_itemClick',
            item: itemObj,
            _clear: true,
        };

        self.sentObject2dataLayer(dataLayerObj);
    };

    /**
     * @description - pomocná funkce pro vytvoření objektu jedné položky z výpisu listu,
     *
     * @param {object} itemData - data pro vytvoření položky
     * @param {string} itemData.elementId - označení elementu (obvykle kombinace list a id)
     * @param {string} itemData.type - typ položky, (zde: video)
     * @param {string} itemData.position - pozice položky v listu
     * @param {string} itemData.systemId - identifikace magazínu
     * @param {string} itemData.id - identifikátor položky
     * @param {string} itemData.versionId  - verze položky (zde timestamp publikace)
     * @param {string} itemData.title - titulek položky
     * @param {string} itemData.category - kategorie položky
     * @param {string} itemData.author - autor položky
     * @param {string} itemData.url - url položky
     * @param {string} reason - důvod zobrazení výpisu doporučených videí (complete/on_pause) - využívá se pro určení názvu listu
     *
     * @return {object} itemObj - objekt s údaji o jedné položce ve výpis
     *
     */
    self.getItemObj = (itemData) => ({
        elementId: itemData.elementId,
        type: itemData.type,
        list: itemData.list,
        position: itemData.position,
        systemId: itemData.systemId,
        id: itemData.id,
        versionId: itemData.versionId,
        title: itemData.title,
        category: itemData.category,
        author: itemData.author,
        url: itemData.url,
    });

    /**
     * @description pomocná funkce pro převod stringu safety na pole
     * @param {string} safetyString - řetězec s hodnotami safety
     * @return {array} safetyArray - pole s hodnotami safety
     */
    self.parseSafety = (safetyString) => {
        const safetyArray =
            safetyString.slice(-1) === ',' ? safetyString.slice(0, -1) : safetyString;
        return safetyArray.split(',');
    };

    /**
     * funkce obsluhující události videa
     */

    /**
     * @description -  fukce pro odeslání události videoplayeru do dataLayeru - neposílá kompletní objekt, jen událost
     *          s id videa a časem
     * @param {object} eventData - object s daty pro odeslání události
     * @param {string} eventData.eventType - označení události dle dokumentace např. 'player_start'
     * @param {number} eventData.timePosition - aktuální pozice videoplayeru ve videu
     * @param {string} eventData.interaction - (nepovinný) druh interakce uživatele
     * @param {boolean} eventData.clear - (nepovinný) - pokud má být do datLayeru odesláno _clearTrue, zadá se s hodnotou true
     */
    self.sentPlayerEventWithTime = (eventData) => {
        utilsConsole('AppWebService.sentPlayerEventWithTime', eventData);
        const dataLayerObj = {};
        const playerObj = {};
        const timeObj = self.getTimeObj(eventData.timePosition);
        const interaction = eventData.interaction || '';
        const eventType = eventData.eventType || '';
        const clear = eventData.clear || false;

        if (eventType && timeObj) {
            playerObj.time = timeObj;

            playerObj.content = {
                id: settings.contentId,
            };

            if (interaction !== '') {
                playerObj.interaction = interaction;
            }

            dataLayerObj.event = eventType;
            dataLayerObj.player = playerObj;

            if (clear) {
                dataLayerObj._clear = true;
            }

            self.sentObject2dataLayer(dataLayerObj);

            return dataLayerObj;
        }

        return `none`;
    };

    /**
     * @description funkce pro odeslání změny nastavení playeru do dataLayer
     * @param {object} setting
     * @param {object} setting.eventType - type eventu
     * @param {object} setting.item
     * @param {object} setting.itemValue
     * @param {object} setting.timePosition
     */
    self.sentPlayerSetting = (setting) => {
        const { eventType, item, itemValue, timePosition } = setting;
        const dataLayerObj = {};
        const playerObj = {
            settings: {},
            time: self.getTimeObj(timePosition),
        };
        if (settings.contentId) {
            playerObj.content = {
                id: settings.contentId,
            };
        }

        playerObj.settings[item] = itemValue;

        dataLayerObj.event = eventType;
        dataLayerObj.player = playerObj;

        self.sentObject2dataLayer(dataLayerObj);
    };

    /**
     * @description funkce pro odeslání změny  playeru (aktuálně variant floating/standard) do dataLayer
     * @param {object} setting
     * @param {string} setting.key - položka playeru, která se mění, např. 'variant'
     * @param {string} setting.value - hodnota položky
     * @param {number} setting.time - aktuální pozice videoplayeru ve videu
     */
    self.sentPlayerVariant = (setting) => {
        const { key, value, time } = setting;
        const dataLayerObj = {};
        const playerObj = {
            time: self.getTimeObj(time),
        };

        if (settings.contentId) {
            playerObj.content = {
                id: settings.contentId,
            };
        }

        playerObj[key] = value;

        dataLayerObj.player = playerObj;

        self.sentObject2dataLayer(dataLayerObj);
    };

    /** description - funkce pro odeslani chyby playeru do dataLayeru
     * @param {object} errorData - objekt s údaji o chybě
     * @param {string} errorData.code - kód chyby
     * @param {string} errorData.message - popis chyby
     * @param {number} timePosition - aktuální poloha videoplayeru
     */

    self.sentPlayerError = (errorData, timePosition) => {
        const dataLayerObj = {};
        let playerObj;
        const timeObj = self.getTimeObj(timePosition);

        if (errorData && timeObj) {
            playerObj = {
                error: `${errorData.code} ${errorData.message}`,
                time: timeObj,
                'content.id': settings.contentId,
            };

            dataLayerObj.event = 'player_error';
            dataLayerObj.player = playerObj;

            self.sentObject2dataLayer(dataLayerObj);
        }
    };

    /**
     * funkce obsluhující události videa - konec
     */

    /**
     * funkce obsluhující události reklamy
     */

    /**
     * @description - fukce pro odeslání údajů o právě načtené reklamě
     *
     * @param {object} adsList - object s daty o načtené reklamě
     * @param {string} adsList.eventType - událost reklamz, aktuálně jen 'player_ads_loaded'
     * @param {string} adsList.id - id reklamy
     * @param {number} adsList.length - délka reklamy (s)
     * @param {number} adsList.skipAfter - po jaké době lze reklamu přeskočit
     * @param {string} adsList.type - typ reklamy (zde: video)
     * @param {string} adsList.slot - umístění reklamy (pre/mid/post)
     * @param {number} adsList.adPosition - pořadí přehrávané reklamy
     *
     */
    self.sentAdList = (adsList) => {
        let slot;
        const { id, skipAfter, length, type, eventType } = adsList;

        switch (adsList.slot) {
            case 'pre':
                slot = 'preroll';
                break;
            case 'post':
                slot = 'postroll';
                break;
            case 'mid':
                slot = 'middle';
                break;
            default:
                slot = 'none';
        }

        const adListObj = {
            list: {
                id,
                length,
                skipAfter,
                type,
                slot,
                position: adsList.adPosition,
            },
        };

        const dataLayerObj = {
            event: eventType,
            'player.content.id': settings.contentId,
            'player.ads': adListObj,
            _clear: true,
        };

        self.sentObject2dataLayer(dataLayerObj);
    };

    /**
     * @description - funkce pro odesílání událostí reklamy do dataLayeru
     *
     * @param {object} adInfo - údaje o reklamě
     * @param {string} adInfo.eventType - typ události dle dokumentace, např. player_ads_pause
     * @param {string} adInfo.id - id reklamy
     * @param {number} adInfo.position - pozice v přehrávané reklamě
     */
    self.sentAdPlay = (adInfo) => {
        const { eventType } = adInfo;
        const currentAdIdObj = {
            id: adInfo.id,
        };

        if (adInfo.position) {
            currentAdIdObj.position = Math.round(adInfo.position);
        }

        const dataLayerObj = {
            event: eventType,
            player: {
                ads: {
                    currentAd: currentAdIdObj,
                },
                content: {
                    id: settings.contentId,
                },
            },
        };

        if (`item` in adInfo && `itemValue` in adInfo) {
            dataLayerObj.player.settings = {};
            dataLayerObj.player.settings[adInfo.item] = adInfo.itemValue;
        }

        self.sentObject2dataLayer(dataLayerObj);
    };

    /**
     * @description - funce pro odesílání údajů o chybě reklamy do dataLayeru
     * @param errorData
     */
    self.sentAdError = (errorData) => {
        const currentAdIdObj = {
            id: errorData.id,
        };

        if (errorData.position) {
            currentAdIdObj.position = Math.round(errorData.position);
        }

        const dataLayerObj = {
            event: 'player_ads_error',
            'player.content.id': settings.contentId,
            'player.ads.currentAd': currentAdIdObj,
            'player.error': errorData.msg,
        };

        self.sentObject2dataLayer(dataLayerObj);
    };

    /**
     * funkce obsluhující události reklamy - konec
     */

    return self;
}
